import React from "react";
//import PropTypes from 'prop-types';
import ThemeColorSelect from "./ThemeColorSelect";
import { useData } from "state/hooks";

export function ThemeColorSelectContainer(props) {
    const { themeId } = props;
    const { preset: presetId } = useData({
        type: "console.themes",
        id: themeId,
    });
    const preset = useData({ type: "console.presets", id: presetId });
    const colorScheme = preset?.color_scheme || {};
    const colorNames = Object.keys(colorScheme)
        .filter((key) => key.includes("_color"))
        .map((key) => key.replace("_color", ""));
    const colors = colorNames.reduce((acc, name) => {
        acc[name] = colorScheme[`${name}_color`];
        return acc;
    }, {});
    return <ThemeColorSelect {...props} colors={colors} />;
}

ThemeColorSelectContainer.propTypes = {};

ThemeColorSelectContainer.defaultProps = {};

export default ThemeColorSelectContainer;
