import { post } from "state/actions/api";

export const buildFavicon =
    (context) =>
    (theme, options = {}) => {
        return post(context)(
            `console/themes/${theme}/favicon`,
            options,
            "buildFavicon"
        );
    };
