import React, { useState } from "react";
import { useActions, useFetch, useForm, usePending } from "state/hooks";
import { useSelector } from "react-redux";
import { selectSettingsId } from "cms/state/selectors/cms";
import Button from "components/Button";
import Preloader from "components/Preloader";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import { create, pathAppend, pathDetach } from "state/actions/data";
import uuid from "uuid/v4";
import IconButton from "components/IconButton";
import Popover from "components/Popover";
import Favicon from "./Favicon";

//import PropTypes from 'prop-types';

function DefaultLayoutsField(props) {
    const { value, path, onChange } = props;
    const { onCreateEntity, onAttach, onDelete } = useActions({
        onCreateEntity: create,
        onAttach: pathAppend,
        onDelete: pathDetach,
    });
    const onCreate = () => {
        const newId = uuid();
        onCreateEntity("cms.layout-ref", { id: newId });
        onAttach(path, newId);
    };
    const val = value || [];
    return (
        <div className={"rows gap-sm"}>
            {val.map((id) => (
                <LayoutRef
                    key={id}
                    id={id}
                    onDelete={() => onDelete(path, id)}
                />
            ))}
            <div className={"cols cols-left"}>
                <Button color={"default"} onClick={onCreate}>
                    Dodaj
                </Button>
            </div>
        </div>
    );
}

function LayoutRef(props) {
    const { id, onDelete } = props;
    return (
        <AggregateRoot type={"cms.layout-ref"} id={id} disableSubmit={true}>
            <div className={"cols cols-middle gap-md"}>
                <Field
                    id={"entry_type"}
                    fieldType={"select"}
                    source={"schema:entries.types"}
                    label={"Typ wpisu"}
                />
                <Field
                    id={"layout"}
                    fieldType={"select"}
                    source={"api:cms.layouts"}
                    label={"Szablon"}
                />
                <div className={"cols cols-middle"}>
                    <Popover
                        trigger={({ onClick }) => (
                            <IconButton
                                icon={"mui-settings"}
                                onClick={onClick}
                            />
                        )}
                        content={({ onClose }) => (
                            <div className={"rows gap-sm pad-md"}>
                                <Field
                                    id={"tags"}
                                    fieldType={"select"}
                                    source={"api:cms.tags"}
                                    label={"Tagi"}
                                    multiple={true}
                                    variant={"inline"}
                                />
                                <Field
                                    id={"archived"}
                                    fieldType={"checkbox"}
                                    label={"Archiwalne"}
                                    variant={"inline"}
                                />
                            </div>
                        )}
                    />
                    <IconButton icon={"mui-close"} onClick={onDelete} />
                </div>
            </div>
        </AggregateRoot>
    );
}

export function WebsiteSettings(props) {
    const {} = props;

    useFetch({
        type: "cms.settings",
        url: "cms/settings",
    });

    const id = useSelector((store) => selectSettingsId(store));
    const { onSubmit } = useForm("cms.settings", id, () => null);
    const isPending = usePending(id, false);

    return (
        <AggregateRoot type={"cms.settings"} id={id} disableSubmit={true}>
            <div className={"settings-view"}>
                <div className={"settings-view-content"}>
                    <div className={"rows gap-lg"}>
                        <div className={"rows gap-sm"}>
                            <h3>Logo</h3>
                            <Field id={"media"} fieldType={"media"} />
                        </div>
                        <div className={"rows gap-sm"}>
                            <h3>Favicon</h3>
                            <Favicon />
                        </div>
                        <div className={"rows gap-sm"}>
                            <h3>SEO</h3>
                            <Field
                                id={"seo_title_template"}
                                label={"Szablon meta title"}
                            />
                            <Field
                                id={"seo_title_default_template"}
                                label={"Szablon domyślnego tytułu"}
                            />
                            <Field
                                id={"footer_signature"}
                                label={"Tekst w stopce"}
                            />
                            <Field
                                id={"sitemap_entry_types"}
                                fieldType={"select"}
                                source={"schema:entries.types"}
                                label={"Typy wpisów w sitemap"}
                                multiple={true}
                            />
                        </div>
                        <div className={"rows gap-sm"}>
                            <h3>schema.org</h3>
                            <Field id={"price_range"} label={"priceRange"} />
                        </div>
                        <div className={"rows gap-sm"}>
                            <h3>Szablony podstron</h3>
                            <Field
                                id={"default_layout"}
                                fieldType={"select"}
                                source={"api:cms.layouts"}
                                label={"Domyślny szablon"}
                            />
                            <Field
                                id={"default_layout_offers"}
                                fieldType={"select"}
                                source={"api:cms.layouts"}
                                label={"Domyślny szablon dla pakietów"}
                            />
                            <Field
                                id={"default_layouts"}
                                path={["cms.settings", id, "default_layouts"]}
                                FieldComponent={DefaultLayoutsField}
                            />
                        </div>
                    </div>
                </div>
                <div className={"settings-view-footer cols-right"}>
                    <Button onClick={() => onSubmit()} size={"large"}>
                        Zapisz
                    </Button>
                </div>
            </div>
            <Preloader visible={isPending} />
        </AggregateRoot>
    );
}

WebsiteSettings.propTypes = {};

WebsiteSettings.defaultProps = {};

export default WebsiteSettings;
