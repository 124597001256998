import React from "react";
//import PropTypes from 'prop-types';

import Favicon from "./Favicon";
import { useCurrentCanonicalDomain, useCurrentTheme } from "../../../hooks";
import { useApiActions, useRequestStatus } from "state/hooks";
import { buildFavicon } from "../../../state/actions/buildFavicon";

export function FaviconContainer(props) {
    const theme = useCurrentTheme();
    const domain = useCurrentCanonicalDomain();
    const { onBuildFavicon } = useApiActions({
        onBuildFavicon: buildFavicon,
    });
    const handleBuild = (options) => onBuildFavicon(theme.id, options);
    const { pending, status, message } = useRequestStatus("buildFavicon");
    const faviconUrl = `${domain?.hostname}/favicon/favicon-32x32.png`;

    return (
        <Favicon
            onSubmit={handleBuild}
            themeId={theme.id}
            status={status}
            isPending={pending}
            message={message}
            faviconUrl={faviconUrl}
        />
    );
}

FaviconContainer.propTypes = {};

FaviconContainer.defaultProps = {};

export default FaviconContainer;
