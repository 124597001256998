import React from "react";
//import PropTypes from 'prop-types';

import GenerateFaviconForm from "./GenerateFaviconForm";
import AlertMessage from "../../../../ui/AlertMessage";

export function Favicon(props) {
    const { themeId, status, isPending, onSubmit, message, faviconUrl } = props;

    return (
        <div className={"rows gap-md"}>
            <div className={"cols gap-md cols-middle"}>
                {!isPending && <img src={faviconUrl} />}
                <GenerateFaviconForm
                    themeId={themeId}
                    onSubmit={onSubmit}
                    isPending={isPending}
                />
            </div>
            {isPending && (
                <AlertMessage type={"pending"}>Generowanie...</AlertMessage>
            )}
            {status === "error" && (
                <AlertMessage type={"error"}>{message}</AlertMessage>
            )}
        </div>
    );
}

Favicon.propTypes = {};

Favicon.defaultProps = {};

export default Favicon;
