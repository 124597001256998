import {useCallback} from "react";
import {useData} from "state/hooks/useData";
import {useActions} from "state/hooks/useActions";
import {FILTER_NS} from "config/list-aliases";
import {change, resetList} from "state/actions/data";

export function useFilter(id, type, list) {

    const data = useData({type: FILTER_NS, id});
    const {onChange} = useActions({onChange: change});
    const {reset} = useActions({reset: resetList});

    const handleChange = useCallback((value) => {
        if (type && list) reset(type, list);
        onChange(FILTER_NS, id, {...value, page: 1});
    }, [onChange, id, reset, type, list]);

    const handleChangeProperty = useCallback((key, value) => {
        handleChange({[key]: value});
    }, [handleChange]);

    return {
        value: data,
        onChange: handleChange,
        onSet: handleChangeProperty
    };
}