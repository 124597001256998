import {useSelector} from "react-redux";
import {selectEntity} from "state/selectors/data";
import {thumbnail} from "util/thumbnail";
import {useMedia} from "containers/media";

export function useCover(media, size = [640, 480], prefix = 'db') {
    const id = media ? media[0] : null;
    const img = useSelector(s=>{
        if (!id) return null;
        return selectEntity(s, [prefix, 'media'].join('.'), id)
    });

    const {thumb} = useMedia();

    if (!media) return null;
    if (!img) return null;

    if (thumb) return thumb(img, size);

    return thumbnail(img.file, size);
}