import { usePending } from "./usePending";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";
import { useMemo } from "react";

export function useRequestStatus(id) {
    const pending = usePending(id);
    const status = useSelector((store) => {
        return selectEntity(store, "app.request-status", id);
    });
    return useMemo(
        () => ({
            ...status,
            pending,
        }),
        [status, pending]
    );
}
