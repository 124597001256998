import React from "react";
import PropTypes from "prop-types";

import "./style.css";

export function AlertMessage(props) {
    const { type, title, children } = props;
    return (
        <div className={`alert-message alert-message--${type}`}>{children}</div>
    );
}

AlertMessage.propTypes = {
    type: PropTypes.oneOf(["info", "warning", "error", "success", "pending"]),
};

AlertMessage.defaultProps = {
    type: "info",
};

export default AlertMessage;
