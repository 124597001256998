import {useContext, useMemo} from "react";
import EntityContext from "containers/context/EntityContext";

export function useEntity() {
    const context = useContext(EntityContext);
    const [type, id] = context ? context.split('/') : [null, null];
    return useMemo(() => ({
        type,
        id
    }), [type, id]);
}