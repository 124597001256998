import {useCallback} from "react";

export function useClick({onClick, id}, allowPropagation = false) {
    return useCallback((evt)=>{
        if (evt && !allowPropagation) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        if (onClick) onClick(id)
    }, [id, onClick, allowPropagation]);
}