import React, { useState } from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import Popover from "components/Popover";
import ThemeColorSelect from "./ThemeColorSelect";

export function GenerateFaviconForm(props) {
    const { themeId, onSubmit, isPending } = props;
    const [color, setColor] = useState(null);
    const [bg, setBg] = useState(null);
    const submit = (close) => {
        onSubmit({ color, bg });
        close();
    };
    return (
        <Popover
            trigger={({ onClick }) => (
                <Button disabled={isPending} onClick={onClick}>
                    Wygeneruj favicon
                </Button>
            )}
            content={({ onClose }) => (
                <div className={"pad-sm rows gap-sm"}>
                    <ThemeColorSelect
                        label={"Kolor"}
                        themeId={themeId}
                        value={color}
                        onChange={setColor}
                    />
                    <ThemeColorSelect
                        label={"Tło"}
                        themeId={themeId}
                        value={bg}
                        onChange={setBg}
                    />
                    <Button onClick={() => submit(onClose)}>Generuj</Button>
                </div>
            )}
        />
    );
}

GenerateFaviconForm.propTypes = {};

GenerateFaviconForm.defaultProps = {};

export default GenerateFaviconForm;
