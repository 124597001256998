import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";

function ThemeColorOption(props) {
    const { name, color, onClick, isSelected } = props;

    return (
        <div
            className={cn("theme-color-select__option", {
                "is-selected": isSelected,
            })}
            onClick={onClick}
            style={{ backgroundColor: color }}
        />
    );
}

export function ThemeColorSelect(props) {
    const { colors, label, onChange, value } = props;
    const names = Object.keys(colors);

    return (
        <div className={"theme-color-select"}>
            <div>
                {label} ({value})
            </div>
            <div className={"theme-color-select__options"}>
                <ThemeColorOption
                    key={"none"}
                    onClick={() => onChange(null)}
                    isSelected={!value}
                />
                {names.map((name) => (
                    <ThemeColorOption
                        key={name}
                        onClick={() => onChange(name)}
                        isSelected={value === name}
                        color={colors[name]}
                        name={name}
                    />
                ))}
            </div>
        </div>
    );
}

ThemeColorSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

ThemeColorSelect.defaultProps = {
    colors: {},
};

export default ThemeColorSelect;
