import React, { useContext, useMemo } from "react";
//import PropTypes from 'prop-types';

import PageSectionsListComponent from "./PageSectionsList";
import { useRouteParam } from "state/hooks";
import AggregateRoot from "containers/AggregateRoot";
import { FormContainer } from "playground/cms/Form";
import { SortableSectionListItem } from "playground/cms/views/Sections/ListItem";
import List from "containers/List";
import { ListLayout } from "playground/cms/List";
import { usePageSectionsList } from "./usePageSectionsList";
import { PageContext } from "./../../PageContext";
import Button from "components/Button";

import "./style.css";
import { useClipboardContext } from "playground/cms/Clipboard";

function EmptyStateCreate(props) {
    const { onCreateSection } = props;
    const { onPaste, types } = useClipboardContext();
    const page = useContext(PageContext);

    return (
        <div className={"pad-lg rows rows-center gap-sm"}>
            <div>Nie ma jeszcze żadnych sekcji</div>
            <div className={"cols gap-xs"}>
                <Button onClick={onCreateSection} variant={"outlined"}>
                    Dodaj sekcję
                </Button>
                {types.includes("cms.sections") && (
                    <Button onClick={() => onPaste({ ...page, section: null })}>
                        Wklej sekcję
                    </Button>
                )}
            </div>
        </div>
    );
}

function EmptyState(props) {
    const { createSection } = props;

    if (createSection)
        return (
            <div className={"drop-placeholder pad-lg rows rows-center gap-sm"}>
                <div>Przeciągnij sekcję z biblioteki w to miejsce</div>
            </div>
        );

    return <EmptyStateCreate {...props} />;
}

function DragHintOverlay(props) {
    return (
        <div className={"drag-hint-overlay"}>
            <div className={"drag-hint"}>
                Przeciągnij sekcję z biblioteki w to miejsce
            </div>
        </div>
    );
}

function PageSectionsList(props) {
    const {
        type,
        id,
        selected,
        createSection,
        onCreateSection,
        onClick,
        callbackFn,
    } = props;

    const [, onEditSection] = useRouteParam("section");

    const { value, onChange, onSort, onDrag } = usePageSectionsList(
        type,
        id,
        callbackFn
    );

    const context = useMemo(
        () => ({
            type,
            id,
            selected,
        }),
        [type, id, selected]
    );

    return (
        <PageContext.Provider value={context}>
            <PageSectionsListComponent>
                <ListLayout>
                    <ListLayout.Content>
                        <List
                            selected={selected}
                            className={"list"}
                            items={value}
                            type={"cms.sections"}
                            onSortEnd={onSort}
                            onAddToList={onDrag}
                            dropPlaceholder={
                                <EmptyState
                                    createSection={createSection}
                                    onCreateSection={onCreateSection}
                                />
                            }
                            watchDrag={true}
                            onClick={onClick || onEditSection}
                            Iterator={SortableSectionListItem}
                            // origin={"local"}
                            originId={id}
                        >
                            {createSection && <DragHintOverlay />}
                        </List>
                    </ListLayout.Content>
                </ListLayout>
            </PageSectionsListComponent>
        </PageContext.Provider>
    );
}

export function PageSectionsListContainer(props) {
    const { type, id } = props;
    return (
        <FormContainer
            type={type}
            id={id}
            disableFetch={true}
            disableSubmit={false}
            onSubmit={() => alert("submit")}
        >
            <PageSectionsList {...props} />
        </FormContainer>
    );
}

PageSectionsListContainer.propTypes = {};

PageSectionsListContainer.defaultProps = {};

export default PageSectionsListContainer;
