import {useSelector} from "react-redux";
import {selectCurrentUserId} from "state/selectors/app";
//import PropTypes from 'prop-types';

export function useCurrentUser() {
    return useSelector(store=>selectCurrentUserId(store));
}

useCurrentUser.propTypes = {};
useCurrentUser.defaultProps = {};
