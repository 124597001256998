import axios from "axios";
import env from "util/env";
import { token } from "util/auth/check";
import { call, put, race, take } from "redux-saga/effects";
import { setSession, tokenExpiresSoon } from "util/auth";
import { authError, logout, refreshToken } from "state/actions/auth";
import { requestMiddleware } from "util/api/adapters";
import { enforceHttps } from "util/uri";
import { apiException } from "state/actions/api";

const API_BASE_URL = env("ZUU_CONSOLE_API_ENDPOINT");

function isExternalRequest(url) {
    return url.indexOf("http") === 0;
}

function* failedAuthentication() {
    yield put(authError());
    yield put(logout());
}

/**
 * Perform api request with authentication handling
 * @param config
 */
export function* request(config) {
    const { context, ...other } = config;

    if (context && context.debug) {
        context.debug(other);
        return null;
    }

    const client = new ApiClient(context);

    const isExternal = isExternalRequest(other.url);

    const params = !isExternal ? requestMiddleware(context, other) : other;

    function* makeRequest() {
        try {
            return yield call(client.request, params);
        } catch (error) {
            yield put(apiException(error));
            throw error;
        }
    }

    try {
        const contextAuth = context && (context.token || context.apiKey);

        if (!isExternal && !token() && !contextAuth) {
            console.log(`Auth token not present, canceling request`, context);
            return null;
        }

        if (!isExternal && !token() && contextAuth) return yield makeRequest();

        if (isExternal || !tokenExpiresSoon()) return yield makeRequest();

        yield put(refreshToken(token()));
        /**
         * Hold request and try to refresh access token
         */
        const { success } = yield race({
            success: take("AUTH.REFRESH.SUCCESS"),
            fail: take("AUTH.REFRESH.FAIL"),
        });

        /**
         * Do the actual request with a new token
         * if refresh was successful
         */
        if (success) {
            return yield makeRequest();
        }

        /**
         * Logout user and redirect to login if otherwise
         */
        return yield failedAuthentication();
    } catch (e) {
        /** Unable to make request **/
        if (!e.response) {
            throw e;
        }
        /** Handle not authorized **/
        if (e.response.status === 401 && !context.token) {
            return yield failedAuthentication();
        }
        /** Handle internal server error **/
        if (e.response.status === 500) {
            if (e?.response?.data?.error) {
                throw new Error(e.response.data.error);
            }
            throw e;
        }
        return null;
    }
}

/**
 * Perform api request without any special handling
 * usually as a part of authentication flow or
 * external requests
 *
 * @param context
 * @param params
 */
export function* rawRequest({ context, ...params }) {
    const client = new ApiClient(context);
    return yield call(client.request, params);
}

export default class ApiClient {
    constructor(context = {}) {
        this.context = context;
        this.axios = axios.create({
            baseURL: API_BASE_URL,
            withCredentials: false,
        });
        this.axios.defaults.headers.common["Content-type"] = "application/json";
        this.axios.interceptors.request.use(this.requestInterceptor);
        this.axios.interceptors.response.use(this.responseInterceptor);

        this.requestInterceptors = [
            this.forceHttpsInterceptor,
            this.authInterceptor,
            this.debugInterceptor,
            this.contextInterceptor,
        ];
    }

    request = (params) => {
        let { url, data } = params;
        const method = params.method || "get";
        const query = params.query || {};
        let options = {
            params: query,
        };

        let payload = {};
        let axiosMethod = "GET";

        if (method !== "get") {
            payload = { _method: method, data: data };
            axiosMethod = "POST";
        }

        return new Promise((resolve, reject) => {
            const requestConfig = {
                url,
                method: axiosMethod,
                data: payload,
                ...options,
            };
            this.axios.request(requestConfig).then(resolve).catch(reject);
        });
    };

    responseInterceptor = (response) => {
        const { headers } = response;
        const accessToken = headers["x-zuu-access-token"];
        const expiresIn = headers["x-zuu-access-token-expires-in"];

        if (accessToken && expiresIn) {
            setSession({ accessToken, expiresIn });
        }

        return response;
    };

    forceHttpsInterceptor = (request) => {
        if (window.location.protocol === "https:")
            request.baseURL = enforceHttps(request.baseURL);

        return request;
    };

    requestInterceptor = (request) => {
        if (isExternalRequest(request.url)) return request;

        this.requestInterceptors.forEach((fn) => (request = fn(request)));
        return request;
    };

    authInterceptor = (request) => {
        const accessToken = token();

        if (accessToken)
            request.headers["Authorization"] = `Bearer ${accessToken}`;

        if (this.context.token) {
            //request.headers['X-zuu-auth-token'] = this.context.token;
            request.headers["X-zuu-api-key"] = this.context.token;
        }

        if (this.context.apiKey) {
            request.params = {
                ...request.params,
                api_key: this.context.apiKey,
            };
        }

        return request;
    };

    debugInterceptor = (request) => {
        if (window.location.hostname.indexOf("joynt") > -1) {
            let parts = request.url.split("/");
            if (parts[0] === "db")
                request.url = ["joynt"].concat(parts.slice(1)).join("/");
        }

        if (this.isDebugMode())
            request.params = {
                ...request.params,
                XDEBUG_SESSION_START: "docker",
            };

        if (document.cookie.indexOf("XDEBUG_PROFILE") > -1)
            request.params = {
                ...request.params,
                XDEBUG_PROFILE: "XDEBUG_ECLIPSE",
            };

        return request;
    };

    contextInterceptor = (request) => {
        const { project, lang } = this.context;

        if (project) request.headers["X-zuu-project-id"] = project;

        if (lang) request.params = { ...request.params, lang };

        return request;
    };

    isDebugMode = () => {
        if (document.cookie.indexOf("XDEBUG_SESSION") > -1) return true;
        if (window.location.hash.indexOf("debug") > -1) return true;

        return false;
    };
}
